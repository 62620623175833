import React, { useState, useRef } from 'react';
import { ERRORMESSAGE } from "@/utils/constant";
import { validateIsCorporateOrCollage, validateDepartmentType, validateWebSite, validateName, validateMobile, validateEmail, validateAboutYou, validatePhone, validateTpoName } from '@/utils/validation';
import { demoRequestApi } from '../action/easycampus';
import Popup from '@/components/contactus/popup';

const DemoForm = ({ isOpen, onClose, }) => {
  const [isCorporateOrCollage, setIsCorporateOrCollage] = useState("corporate");
  const [departmentType, setDepartmentType] = useState("");
  const [webUrl, setWebUrl] = useState("");
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [representativeMobile, setRepresentativeMobile] = useState("");
  const [email, setEmail] = useState("");
  const [representativeEmail, setRepresentativeEmail] = useState("");
  const [aboutYou, setAboutYou] = useState("");
  const [designation, setDesignation] = useState("");
  const [demoForm, setDemoForm] = useState(false);
  const [formMessage, setFormMessage] = useState("");
  

  const [isCorporateOrCollageErrorMessage, setIsCorporateOrCollageErrorMessage] = useState("");
  const [departmentTypeErrorMessage, setDepartmentTypeErrorMessage] = useState("");
  const [webUrlErrorMessage, setWebUrlErrorMessage] = useState("");
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [mobileErrorMessage, setMobileErrorMessage] = useState("");
  const [representativeMobileErrorMessage, setRepresentativeMobileErrorMessage] = useState("");
  const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [representativeEmailErrorMessage, setRepresentativeEmailErrorMessage] = useState("");
  const [aboutYouErrorMessage, setAboutYouErrorMessage] = useState("");
  const [designationErrorMessage, setDesignationErrorMessage] = useState("");

  const isCorporateOrCollageInputRef = useRef(null);
  const departmentTypeInputRef = useRef(null);
  const webUrlInputRef = useRef(null);
  const nameInputRef = useRef(null);
  const mobileInputRef = useRef(null);
  const phoneNumberInputRef = useRef(null);
  const representativeMobileInputRef = useRef(null);
  const emailInputRef = useRef(null);
  const representativeEmailInputRef = useRef(null);
  const aboutYouInputRef = useRef(null);
  const designationInputRef = useRef(null);


  const handleIsCorporateOrCollage = (e) => {
    if (e.target.value === "university") {
      setDepartmentTypeErrorMessage(ERRORMESSAGE.UNIVERSITY_OR_COLLAGE_TYPE_IS_REQUIRED)
    } else if (e.target.value === "corporate") {
      setDepartmentTypeErrorMessage(ERRORMESSAGE.CORPORATE_NAME_IS_REQUIRED)
    }
    setIsCorporateOrCollage(e.target.value)
    setIsCorporateOrCollageErrorMessage("");
    setDepartmentType("");
  }


  const handleDepartmentType = (e) => {
    if (e.target.value.trim() === "") {
      setDepartmentTypeErrorMessage(ERRORMESSAGE.UNIVERSITY_OR_COLLAGE_TYPE_IS_REQUIRED);
      departmentTypeInputRef.current.focus();
    } else {
      setDepartmentTypeErrorMessage(validateDepartmentType(e.target.value.trim()));
      departmentTypeInputRef.current.focus();
    }
    setDepartmentType(e.target.value);
  };

  const handleWebsiteUrlChange = (e) => {
    if (e.target.value.trim() === "") {
      setWebUrlErrorMessage(ERRORMESSAGE.WEBSITE_REQUIRED);
      webUrlInputRef.current.focus();
    } else {
      setWebUrlErrorMessage(validateWebSite(e.target.value.trim()));
      webUrlInputRef.current.focus();
    }
    setWebUrl(e.target.value);
  };

  const handleName = (e) => {
    if (e.target.value.trim() === "") {
      setNameErrorMessage(ERRORMESSAGE.NAME_IS_REQUIRED);
      nameInputRef.current.focus();
    } else {
      setNameErrorMessage(validateName(e.target.value.trim()));
      nameInputRef.current.focus();
    }
    setName(e.target.value);
  };

  const handleMobile = (e) => {
    if (e.target.value.trim() === "") {
      setMobileErrorMessage(ERRORMESSAGE.MOBILE_NUMBER_IS_REQURED);
      mobileInputRef.current.focus();
    } else {
      setMobileErrorMessage(validateMobile(e.target.value.trim()));
      mobileInputRef.current.focus();
    }
    setMobile(e.target.value);
  };

  const handlerepresentativeMobile = (e) => {
    if (e.target.value.trim() === "") {
      setRepresentativeMobileErrorMessage(ERRORMESSAGE.MOBILE_NUMBER_IS_REQURED);
      representativeMobileInputRef.current.focus();
    } else {
      setRepresentativeMobileErrorMessage(validateMobile(e.target.value.trim()));
      representativeMobileInputRef.current.focus();
    }
    setRepresentativeMobile(e.target.value);
  };

  const handlePhoneNumber = (e) => {
    if (e.target.value.trim() === "") {
    } else {
      setPhoneNumberErrorMessage(validatePhone(e.target.value.trim()));
      phoneNumberInputRef.current.focus();
    }
    setPhoneNumber(e.target.value);
  };

  const handleEmail = (e) => {
    if (e.target.value.trim() === "") {
      setEmailErrorMessage(ERRORMESSAGE.EMAIL_IS_REQURED);
      emailInputRef.current.focus();
    } else {
      setEmailErrorMessage(validateEmail(e.target.value.trim()));
      emailInputRef.current.focus();
    }
    setEmail(e.target.value);
  };

  const handleaboutYou = (e) => {
    if (e.target.value.trim() === "") {
      setAboutYouErrorMessage(ERRORMESSAGE.MESSAGE_IS_REQUIRED);
      aboutYouInputRef.current.focus();
    } else {
      setAboutYouErrorMessage(validateAboutYou(e.target.value.trim()));
      aboutYouInputRef.current.focus();
    }
    setAboutYou(e.target.value);
  }

  const handledesignationChange = (e) => {
    if (e.target.value.trim() === "") {
      setDesignationErrorMessage(ERRORMESSAGE.DESIGNATION_IS_REQUIRED);
      designationInputRef.current.focus();
    } else {
      setDesignationErrorMessage(validateTpoName(e.target.value.trim()));
      designationInputRef.current.focus();
    }
    setDesignation(e.target.value);
  };

  const handleRespresentativeEmail = (e) => {
    if (e.target.value.trim() === "") {
      setRepresentativeEmailErrorMessage(ERRORMESSAGE.EMAIL_IS_REQURED);
      representativeEmailInputRef.current.focus();
    } else {
      setRepresentativeEmailErrorMessage(validateEmail(e.target.value.trim()));
      representativeEmailInputRef.current.focus();
    }
    setRepresentativeEmail(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setIsCorporateOrCollageErrorMessage("");
    setDepartmentTypeErrorMessage("");
    setNameErrorMessage("");
    setMobileErrorMessage("");
    setPhoneNumberErrorMessage("");
    setRepresentativeMobileErrorMessage("");
    setEmailErrorMessage("");
    setAboutYouErrorMessage("");
    setRepresentativeEmailErrorMessage("");

    if (!isCorporateOrCollage) {
      setIsCorporateOrCollageErrorMessage(ERRORMESSAGE.SELECT_IS_UNIVERSITY_OR_COLLAGE_NAME);
    } else if (isCorporateOrCollage) {
      setIsCorporateOrCollageErrorMessage(validateIsCorporateOrCollage(isCorporateOrCollage.trim()));
    }


    if (isCorporateOrCollage === "university") {
      // console.log("isCorporateOrCollage", isCorporateOrCollage);
      if (!departmentType) {
        setDepartmentTypeErrorMessage(ERRORMESSAGE.UNIVERSITY_OR_COLLAGE_TYPE_IS_REQUIRED);
      } else if (departmentType) {
        setDepartmentTypeErrorMessage(validateDepartmentType(departmentType.trim()));
      }
    } else {
      if (!departmentType) {
        setDepartmentTypeErrorMessage(ERRORMESSAGE.CORPORATE_TYPE_IS_REQUIRED);
      } else if (departmentType) {
        setDepartmentTypeErrorMessage(validateDepartmentType(departmentType.trim()));
      }
    }

    if (!webUrl) {
      setWebUrlErrorMessage(ERRORMESSAGE.WEBSITE_REQUIRED);
    } else if (webUrl) {
      setWebUrlErrorMessage(validateWebSite(webUrl.trim()));
    }

    if (!name) {
      setNameErrorMessage(ERRORMESSAGE.NAME_IS_REQUIRED);
    } else if (name) {
      setNameErrorMessage(validateName(name.trim()));
    }

    if (!mobile) {
      setMobileErrorMessage(ERRORMESSAGE.MOBILE_NUMBER_IS_REQURED);
    } else if (mobile) {
      setMobileErrorMessage(validateMobile(mobile.trim()));
    }

    if (!phoneNumber) {
      setPhoneNumberErrorMessage(ERRORMESSAGE.PHONE_NUMBER_IS_REQURED);
    } else if (phoneNumber){
      setPhoneNumberErrorMessage(validatePhone(phoneNumber.trim()));
    }

    if (!representativeMobile) {
      setRepresentativeMobileErrorMessage(ERRORMESSAGE.MOBILE_NUMBER_IS_REQURED);
    } else if (representativeMobile) {
      setRepresentativeMobileErrorMessage(validateMobile(representativeMobile.trim()));
    }

    if (!email) {
      setEmailErrorMessage(ERRORMESSAGE.EMAIL_IS_REQURED);
    } else if (email) {
      setEmailErrorMessage(validateEmail(email.trim()));
    }

    if (!representativeEmail) {
      setRepresentativeEmailErrorMessage(ERRORMESSAGE.EMAIL_IS_REQURED);
    } else if (representativeEmail) {
      setRepresentativeEmailErrorMessage(validateEmail(representativeEmail.trim()));
    }

    if (!aboutYou) {
      setAboutYouErrorMessage(ERRORMESSAGE.MESSAGE_IS_REQUIRED);
    } else if (aboutYou) {
      setAboutYouErrorMessage(validateAboutYou(aboutYou.trim()));
    }

    if (!designation) {
      setDesignationErrorMessage(ERRORMESSAGE.DESIGNATION_IS_REQUIRED);
    } else if (designation) {
      setDesignationErrorMessage(validateTpoName(designation.trim()));
    }

    if (!isCorporateOrCollage) {
      isCorporateOrCollageInputRef.current.focus();
      return;
    } else if (isCorporateOrCollage) {
      if (validateIsCorporateOrCollage(isCorporateOrCollage.trim())) {
        isCorporateOrCollageInputRef.current.focus();
        return;
      }
    }

    if (isCorporateOrCollage === "university") {
      if (!departmentType) {
        departmentTypeInputRef.current.focus();
        return;
      } else if (departmentType) {
        if (validateIsCorporateOrCollage(departmentType.trim())) {
          departmentTypeInputRef.current.focus();
          return;
        }
      }
    } else {
      if (!departmentType) {
        departmentTypeInputRef.current.focus();
        return;
      } else if (departmentType) {
        if (validateIsCorporateOrCollage(departmentType.trim())) {
          departmentTypeInputRef.current.focus();
          return;
        }
      }
    }

    if (!webUrl) {
      webUrlInputRef.current.focus();
      return;
    } else if (webUrl) {
      if (validateWebSite(webUrl.trim())) {
        webUrlInputRef.current.focus();
        return;
      }
    }

    if (!email) {
      emailInputRef.current.focus();
      return;
    } else if (email) {
      if (validateEmail(email.trim())) {
        emailInputRef.current.focus();
        return;
      }
    }

    if (!mobile) {
      mobileInputRef.current.focus();
      return;
    } else if (mobile) {
      if (validateMobile(mobile.trim())) {
        mobileInputRef.current.focus();
        return;
      }
    }

    if (!phoneNumber) {
      phoneNumberInputRef.current.focus();
      return;
    } else if (phoneNumber){
    if (validatePhone(phoneNumber.trim())) {
        phoneNumberInputRef.current.focus();
        return;
      }
    }


    if (!name) {
      nameInputRef.current.focus();
      return;
    } else if (name) {
      if (validateName(name.trim())) {
        nameInputRef.current.focus();
        return;
      }
    }

    if (!designation) {
      designationInputRef.current.focus();
      return;
    } else if (designation) {
      if (validateTpoName(designation.trim())) {
        designationInputRef.current.focus();
        return;
      }
    }

    if (!representativeEmail) {
      representativeEmailInputRef.current.focus();
      return;
    } else if (representativeEmail) {
      if (validateEmail(representativeEmail.trim())) {
        representativeEmailInputRef.current.focus();
        return;
      }
    }

    if (!representativeMobile) {
      representativeMobileInputRef.current.focus();
      return;
    } else if (representativeMobile) {
      if (validateMobile(representativeMobile.trim())) {
        representativeMobileInputRef.current.focus();
        return;
      }
    }

    if (!aboutYou) {
      aboutYouInputRef.current.focus();
      return;
    } else if (aboutYou) {
      if (validateAboutYou(aboutYou.trim())) {
        aboutYouInputRef.current.focus();
        return;
      }
    }

    let formData = {
    demoType:isCorporateOrCollage,
    entityType:departmentType,
    entityWebUrl:webUrl,
    entityEmail:email,
    entityMobile:mobile,
    entityPhoneNumber:phoneNumber,
    representativeName:name,
    representativeDesignation:designation,
    representativeEmail:representativeEmail,
    representativeMobile:representativeMobile,
    message:aboutYou
    }
    demoRequestDataSubmit(formData);
  }

  const demoRequestDataSubmit = async (formData) => {
    console.log("lets talk", formData);
    try {

      const response = await demoRequestApi(formData);

      if (response.data.hasError == false) {
        setFormMessage(response.data);
        console.log("lets talk formsumbit", response.data.data);
        setDemoForm(true)
        onClose();
      }

      if (response.data.hasError === true) {
        setFormMessage(response.data);
        setDemoForm(true)
      }

    } catch (error) {
      console.error(error);
    }
  };



  return (
    <>
  
 {demoForm && (
    <Popup
      isOpen={demoForm}
      onClose={() => setDemoForm(false)}
      message={formMessage}
    />
  )}
      {isOpen && (
        <div className='model_wrapper'>
          <div className="model_container scroll-demoform">
            <button
              onClick={onClose}
              type="button"
              className="crossicon z-10"
              data-modal-hide="default-modal"
            >
              <svg
                className="crossiconSvg"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
            <form className='text-start px-5'
              onSubmit={handleFormSubmit}>
              <div className="details_head mt-5">
                <div className="details__head">
                  Campus/Corporate Details
                </div>
              </div>
              <div className="details_border">
                <div className="w-full mb-5">
                  <select
                  
                    onChange={handleIsCorporateOrCollage}
                    ref={isCorporateOrCollageInputRef}
                    value={isCorporateOrCollage}>
                    <option value=""disabled>
                      Choose one
                    </option>
                    <option key={`university`}
                      value="university">
                      University/College
                    </option>
                    <option key={`corporate`}
                      value="corporate">
                      Corporate
                    </option>
                  </select>
                  <div className="text-red-500" id="isCorporateOrCollageErrorMessage">
                    {isCorporateOrCollageErrorMessage}
                  </div>
                </div>

                <div className='grid grid-cols-1 lg:grid-cols-2 gap-5'>
                  <div>
                    <label htmlFor="name" className="block text-gray-700">
                      <>
                        {isCorporateOrCollage === "university" ? "University/Collage Name" : "Corporate Name"}
                        <span className="text-red-500"> *</span>
                      </>
                    </label>
                    <div>
                      <input
                        className="form_input"
                        onChange={handleDepartmentType}
                        ref={departmentTypeInputRef}
                        value={departmentType}
                        disabled={isCorporateOrCollage === ""}
                        placeholder={isCorporateOrCollage ?
                          (isCorporateOrCollage === "university" ? "Enter University/Collage Name" : "Enter Corporate Name") :
                          "Choose one"
                        }/>
                    </div>
                    <div className="text-red-500" id="isCorporateOrCollageErrorMessage">
                      {departmentTypeErrorMessage}
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-gray-700">
                      Website URL <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Website Url"
                      ref={webUrlInputRef}
                      value={webUrl}
                      onChange={handleWebsiteUrlChange}
                      required
                    />
                    <div
                      id="webUrlErrorMessage"
                      className="text-red-500 text-sm">
                      {webUrlErrorMessage}
                    </div>
                  </div>
                  <div>
                    <label htmlFor="email" className="block text-gray-700">
                      Email <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="email"
                      value={email}
                      ref={emailInputRef}
                      onChange={handleEmail}
                      placeholder="Official Email"
                      required />
                    <div
                      id="EmailErrorMessage"
                      className="text-red-500 text-sm">
                      {emailErrorMessage}
                    </div>
                  </div>
                  <div>
                    <label htmlFor="mobile" className="block text-gray-700">
                      Mobile Number <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="tel"
                      pattern="[6789][0-9]{9}"
                      minLength={10}
                      maxLength={10}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/\D/g, "");
                      }}
                      ref={mobileInputRef}
                      value={mobile}
                      onChange={handleMobile}
                      placeholder="Mobile Number"
                      required
                    />
                    <div id="mobileErrorMessage" className="text-red-500 text-sm">
                      {mobileErrorMessage}
                    </div>
                  </div>
                  <div className=''>
                    <label htmlFor="tel" className="block text-gray-700">
                      Phone Number 
                    </label>
                    <span className='flex'>
                    <input
                      placeholder="001"
                      className='w-16'
                    />
                    <input
                      ref={phoneNumberInputRef}
                      type="tel"
                      pattern="[6789][0-9]{9}"
                      minLength={8}
                      maxLength={10}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/\D/g, "");
                      }}
                      placeholder="Enter Phone Number"
                      className="form_input ml-2"
                      onChange={handlePhoneNumber}
                      value={phoneNumber}
                    />
                    </span>
                    <div
                      id="phoneNumberErrorMessage"
                      className="text-red-500 text-sm"
                    >
                      {phoneNumberErrorMessage}
                    </div>
                  </div>
                </div>
              </div>
              <div className="details_head mt-10">
                <div className="details__head">
                  Representative Details
                </div>
              </div>
              <div className="details_border ">
                <div className='grid grid-cols-1 lg:grid-cols-2 gap-5'>
                  <div>
                    <label htmlFor="name" className="block text-gray-700">
                      Name <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Name"
                      value={name}
                      ref={nameInputRef}
                      onChange={handleName}
                      required
                    />
                    <div
                      id="nameErrorMessage"
                      className="text-red-500 text-sm"
                    >
                      {nameErrorMessage}
                    </div>
                  </div>
                  <div>
                    <label htmlFor="name" className="block text-gray-700">
                      Designation <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Designation"
                      ref={designationInputRef}
                      value={designation}
                      onChange={handledesignationChange}
                      required
                    />
                    <div
                      id="designationErrorMessage"
                      className="text-red-500 text-sm">
                      {designationErrorMessage}
                    </div>
                  </div>
                  <div>
                    <label htmlFor="name" className="block text-gray-700">
                      Email <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Official Email"
                      value={representativeEmail}
                      ref={representativeEmailInputRef}
                      onChange={handleRespresentativeEmail}
                      required
                    />
                    <div
                      id="representativeEmailErrorMessage"
                      className="text-red-500 text-sm">
                      {representativeEmailErrorMessage}
                    </div>
                  </div>
                  <div>
                    <label htmlFor="mobile" className="block text-gray-700">
                      Mobile Number <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="tel"
                      pattern="[6789][0-9]{9}"
                      minLength={10}
                      maxLength={10}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/\D/g, "");
                      }}
                      ref={representativeMobileInputRef}
                      value={representativeMobile}
                      onChange={handlerepresentativeMobile}
                      placeholder="Mobile Number"
                      required
                    />
                    <div id="representativeMobileErrorMessage" className="text-red-500 text-sm">
                      {representativeMobileErrorMessage}
                    </div>
                  </div>
                </div>
              </div>
              <div className='mt-4'>
                <label htmlFor="message" className="block text-gray-700">
                  Message <span className="text-red-500">*</span>
                </label>
                <textarea
                  placeholder="Message"
                  onChange={handleaboutYou}
                  ref={aboutYouInputRef}
                  value={aboutYou}
                  minLength={20}
                  className=' border rounded-md focus:ring-blue-500 focus:border-blue-50 p-3 w-full'
                  required
                />
                <div
                  id="aboutYouErrorMessage"
                  className="text-red-500 text-sm"
                >
                  {aboutYouErrorMessage}
                </div>
              </div>

              <div className="flex justify-center mt-5">
                <button
                  className='p-4 mt-5 bg-[#1e336a] text-[#fff] rounded'
                  type="submit"
                  value="Submit"
                  onClick={handleFormSubmit}
                > Submit</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  )
}

export default DemoForm